import { StatusEnum } from '@enums/Status.enum';
import { UserLevelEnum } from '@enums/UserEnum';
import { UserRoles } from '@interfaces/Roles.interface';
import { CurrentUser, User } from '@interfaces/User.interfaces';
import { WalletDetailsProps } from '@interfaces/Wallets.interfaces';
import { createContext } from 'react';

export interface UserContext extends CurrentUser {
  setUser: (user: User) => void;
  setRoles: React.Dispatch<React.SetStateAction<UserRoles>>;
  roles: UserRoles;
  isAdmin: boolean;
  isRealmAdmin: boolean;
  currentWallet: WalletDetailsProps;
  setCurrentWallet: React.Dispatch<React.SetStateAction<WalletDetailsProps>>;
}

export const startContextRoles = {
  id: '',
  level: '' as UserLevelEnum,
  roles: [
    {
      id: '',
      name: '',
      persmissions: [
        {
          id: '',
          name: '',
          action: '',
          resource: '',
        },
      ],
    },
  ],
};

export const startUserContext: UserContext = {
  id: '',
  cpf: '',
  firstName: '',
  lastName: '',
  email: '',
  status: '' as StatusEnum,
  birthdate: '',
  userOrgLevel: '' as UserLevelEnum,
  phone: '',
  external: {
    atlasUserHash: '',
    identity: '',
  },
  realm: {
    id: '',
    level: '' as UserLevelEnum,
    name: '',
  },
  organizations: [
    {
      id: '',
      legalName: '',
      tradingName: '',
      document: '',
    },
  ],
  roles: startContextRoles,
  setUser: () => '',
  setRoles: () => '',
  currentWallet: {} as WalletDetailsProps,
  setCurrentWallet: () => '',
  isAdmin: false,
  isRealmAdmin: false,
  createdAt: undefined,
  pinErrorsCount: 0,
  hasMfa: false,
  hasPin: false,
};

export const UserContext = createContext<UserContext>(startUserContext);
