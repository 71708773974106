import { SpinnerPage } from '@components';
import { UserLevelEnum } from '@enums/UserEnum';
import { environment } from '@environments/environment';
import { UserRoles } from '@interfaces/Roles.interface';
import { CurrentUser } from '@interfaces/User.interfaces';
import { WalletDetailsProps } from '@interfaces/Wallets.interfaces';
import {
  customerEvents,
  groupCustomerIo,
  identifyCustomerIo,
  resetCustomerIoIdentity,
} from '@lib/customerIo';
import { setSentryContext, setSentryUser } from '@lib/sentry';
import { UserContext, startContextRoles, startUserContext } from '@providers';
import { getRoles } from '@services/roles';
import { useGetCurrentUserQuery } from '@services/user/hooks/useGetCurrentUserQuery';
import { initAtlas } from '@utils/atlas';
import { filterBlockUrl } from '@utils/filterUrls';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface UserProviderRenderProps {
  isAdmin: boolean;
  isRealmAdmin: boolean;
}

interface UserProviderProps {
  children:
    | React.ReactNode
    | React.ReactNode[]
    | ((props: UserProviderRenderProps) => React.ReactNode | React.ReactNode[]);
  getUserRoles?: (roles: UserRoles) => void;
}

export const UserProvider = ({ children, getUserRoles }: UserProviderProps) => {
  const history = useNavigate();

  const [roles, setRoles] = useState<UserRoles>(startContextRoles);
  const [currentWallet, setCurrentWallet] = useState<WalletDetailsProps>(
    {} as WalletDetailsProps
  );

  const { data: user, isError } = useGetCurrentUserQuery();

  const selectedOrganizationId = localStorage.getItem('organization') || '';

  const getUserRole = useCallback(async () => {
    if (filterBlockUrl(window.location.pathname)) {
      const response = await getRoles();
      setRoles(response);

      getUserRoles && getUserRoles(response);

      if (!user) return;

      const currentOrg = user.organizations.find(
        (organization) => organization.id === selectedOrganizationId
      );

      if (!currentOrg) return;

      setSentryContext('Organization', {
        id: currentOrg.id,
        legalName: currentOrg.legalName,
        tradingName: currentOrg.tradingName,
        level: response.level,
      });
    }
  }, [getUserRoles, setRoles, user, selectedOrganizationId]);

  useEffect(() => {
    if (!user) return;

    const setCurrentUserRoles = (data: CurrentUser) => {
      localStorage.setItem('user', JSON.stringify(data));

      if (localStorage.getItem('organization')) {
        getUserRole();

        return;
      }

      if (data?.organizations.length === 1) {
        localStorage.setItem('organization', data.organizations[0].id);

        getUserRole();

        history('/wallets');
      } else if (data?.organizations.length > 1) {
        history('/organizations');
      } else {
        history('/unauthorized');
      }
    };

    setCurrentUserRoles(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, getUserRole, selectedOrganizationId]);

  useEffect(() => {
    if (isError) {
      localStorage.clear();
      resetCustomerIoIdentity();
      history('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const isAdmin = useCallback(() => {
    return (
      roles.level === UserLevelEnum.ADMIN ||
      user?.realm.level === UserLevelEnum.ADMIN ||
      user?.realm.level === UserLevelEnum.ROOT
    );
  }, [roles, user]);

  const isRealmAdmin = useCallback(() => {
    return (
      user?.realm.level === UserLevelEnum.ADMIN ||
      user?.realm.level === UserLevelEnum.ROOT
    );
  }, [user]);

  useEffect(() => {
    if (environment.production && user?.id !== '' && user?.email) {
      initAtlas(user);
    }
  }, [user, selectedOrganizationId]);

  useEffect(() => {
    if (environment.production && !!user && user?.id !== '') {
      const userLoggedIn = localStorage.getItem('loggedInCustomerIo');

      identifyCustomerIo(user);
      groupCustomerIo(user);

      setSentryUser(user);

      if (userLoggedIn) {
        customerEvents.userLoggedIn(JSON.parse(userLoggedIn));

        localStorage.removeItem('loggedInCustomerIo');
      }
    }
  }, [user]);

  const userFinal = useMemo(() => {
    return user ? user : startUserContext;
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        ...userFinal,
        setUser: () => '',
        setRoles,
        roles,
        isAdmin: isAdmin(),
        isRealmAdmin: isRealmAdmin(),
        currentWallet,
        setCurrentWallet,
      }}
    >
      {user ? (
        typeof children === 'function' ? (
          children({
            isAdmin: isAdmin(),
            isRealmAdmin: isRealmAdmin(),
          })
        ) : (
          children
        )
      ) : (
        <SpinnerPage />
      )}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within an UserProvider');
  }

  return context;
};
