import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ICONS } from '@constants/icons';
import { StatusDescriptionEnum, StatusEnum } from '@enums/Status.enum';
import { UserLevelEnum } from '@enums/UserEnum';
import { useFilterColumns } from '@hooks/useFilterColumns';
import { CurrentUser, User } from '@interfaces/User.interfaces';
import { eventsHandler } from '@lib/eventsHandler';
import { Avatar, Button, Icon, Status, Tag } from '@portao3-web/ui';
import { useDrawer, useUser } from '@providers';
import { Dispatch, SetStateAction } from 'react';

export const useUserColumns = (
  setShowDeactivateUserModal: Dispatch<
    SetStateAction<{
      open: boolean;
      userId: string;
      fullName: string;
      email: string;
    }>
  >
) => {
  const { t } = useTranslation();
  const { openDrawer } = useDrawer();
  const { id: userId } = useUser();

  const userAccess = {
    [UserLevelEnum.ROOT]: 'general.admin',
    [UserLevelEnum.ADMIN]: 'general.admin',
    [UserLevelEnum.USER]: 'general.collaborator',
  };

  const isRealmAdmin = (item: User) => item.realm.level === UserLevelEnum.ADMIN;
  const isOrgAdmin = (item: User) => item.userOrgLevel === UserLevelEnum.ADMIN;
  const isAnUserAdmin = (item: User) => isRealmAdmin(item) || isOrgAdmin(item);

  const getSecurityStatus = (active: boolean) => {
    if (active) {
      return {
        icon: `${ICONS['check-circle-solid']} text-p3 text-success-400`,
        text: t('general.active'),
      };
    }

    return {
      icon: `${ICONS['triangle-exclamation']} text-p3 text-warning-400`,
      text: t('general.pending'),
    };
  };

  const columns: ColumnDef<CurrentUser>[] = [
    {
      accessorKey: 'avatar',
      header: '',
      cell: ({ row }) => {
        const fullName = `${row.original.firstName} ${row.original.lastName}`;
        return (
          <div className="px-4">
            <Avatar imageSrc="" userName={fullName} size="small" />
          </div>
        );
      },
    },
    {
      accessorKey: 'name',
      header: t('general.name'),
      cell: ({ row }) => {
        return (
          <div className="flex items-center">
            <p className="text-p2">{`${row.original.firstName} ${row.original.lastName}`}</p>
            {isAnUserAdmin(row.original) && (
              <Icon size="xlarge">
                <i
                  className={`fa-solid fa-crown`}
                  style={{ color: 'var(--product-warning-w500)' }}
                />
              </Icon>
            )}
          </div>
        );
      },
      meta: {
        width: 'auto',
        filter: {
          disabled: true,
          initialSelected: true,
        },
      },
    },
    {
      accessorKey: 'userOrgLevel',
      header: t('general.access'),
      meta: {
        width: 120,
        filter: {
          initialSelected: true,
        },
      },
      cell: ({ cell }) => {
        const user = cell.row.original.userOrgLevel;

        return t(
          userAccess[user as keyof typeof userAccess],
          cell.row.original.userOrgLevel
        );
      },
    },
    {
      accessorKey: 'status',
      header: t('general.status'),
      meta: {
        width: 120,
        filter: {
          initialSelected: true,
        },
      },
      cell: ({ cell }) => (
        <Status
          status={
            StatusEnum[cell.row.original.status as keyof typeof StatusEnum]
          }
        >
          {StatusDescriptionEnum[cell.row.original.status]}
        </Status>
      ),
    },
    {
      accessorKey: 'email',
      header: t('general.email'),
      meta: {
        width: 240,
        filter: {
          disabled: true,
          initialSelected: true,
        },
      },
      cell: ({ cell }) => cell.getValue<string>(),
    },
    {
      accessorKey: 'hasPin',
      header: 'PIN',
      meta: {
        width: 120,
      },
      cell: ({ cell }) => (
        <Tag
          className="text-neutral-100 !bg-neutral-20"
          icon={
            <i className={getSecurityStatus(cell.getValue<boolean>()).icon} />
          }
          text={getSecurityStatus(cell.getValue<boolean>()).text}
          showCloseButton={false}
        />
      ),
    },
    {
      accessorKey: 'hasMfa',
      header: t('general.mfa'),
      meta: {
        width: 120,
      },
      cell: ({ cell }) => (
        <Tag
          className="text-neutral-100 !bg-neutral-20"
          icon={
            <i className={getSecurityStatus(cell.getValue<boolean>()).icon} />
          }
          text={getSecurityStatus(cell.getValue<boolean>()).text}
          showCloseButton={false}
        />
      ),
    },
    {
      accessorKey: '',
      header: t('general.actions'),
      cell: ({ cell }) => {
        const item = cell.row.original;
        const id = cell.row.id;

        return (
          <div className="flex flex-row gap-2">
            <Button
              type="button"
              variant="tertiary"
              size="small"
              className="w-6"
              onClick={() => openDrawer('edit-realm-user', { userId: id })}
              data-testid={`view-user-${id}`}
            >
              <Icon size="medium" key="view-user-button">
                <i className="fa-regular fa-pencil fa-1x text-neutral-100" />
              </Icon>
            </Button>

            <Button
              type="button"
              variant="tertiary"
              size="small"
              className="w-6"
              onClick={() => {
                openDrawer('wallet-balance', { userId: id });
                eventsHandler.openDrawerEditUserBalanceAdminUsers();
              }}
              data-testid={`edit-user-${id}`}
            >
              <Icon size="medium" key="edit-user-button">
                <i className="fa-regular fa-wallet fa-1x text-neutral-100" />
              </Icon>
            </Button>

            <Button
              type="button"
              variant="tertiary"
              size="small"
              className="w-6"
              data-testid={`deactivate-user-${id}`}
              disabled={item.status === StatusEnum.DEACTIVATED || userId === id}
              onClick={() =>
                setShowDeactivateUserModal({
                  open: true,
                  userId: item.id,
                  fullName: `${item.firstName} ${item.lastName}`,
                  email: item.email,
                })
              }
            >
              <Icon size="medium" key="remove-user-button">
                <i
                  className={`fa-regular fa-trash fa-1x ${item.status !== StatusEnum.DEACTIVATED && userId !== id ? 'text-neutral-100' : ''} `}
                />
              </Icon>
            </Button>
          </div>
        );
      },
      meta: {
        width: 120,
        filter: {
          disabled: true,
          initialSelected: true,
        },
      },
    },
  ];

  return useFilterColumns('organization-users-columns', columns);
};
