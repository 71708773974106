import { endpoint, organization, realm } from '@constants/endpoints';
import { limitPerPage } from '@constants/limitPerPage';
import { environment } from '@environments/environment';
import { ListResponse } from '@interfaces/Common.interfaces';
import { Customer } from '@interfaces/Customer.interfaces';
import api from '@services/api';
import { removeEmptyObjectAttributes } from '@utils/removeEmptyObjectAttributes';

export const findCustomerList = async (
  next: null | string,
  name?: string | null,
  limit?: number
): Promise<ListResponse<Customer>> => {
  const params = { next, limit: limit || limitPerPage, name };
  removeEmptyObjectAttributes(params);

  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.customers
    }`,
    { params }
  );
  const { data } = response;

  return data;
};
