import { ColumnDef } from '@tanstack/react-table';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { getCountryNameBySigla } from '@constants/countries';
import { ICONS } from '@constants/icons';
import { useFilterColumns } from '@hooks/useFilterColumns';
import { Transactions } from '@interfaces/Transactions.interfaces';
import { DrawerTransactionDetails } from '@modules/drawer';
import {
  Button,
  TableDate,
  TableIcon,
  TableItemTitleSub,
} from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { transactionKeys } from '@services/transactions/hooks';
import { useQueryClient } from '@tanstack/react-query';
import {
  calcCurrencyValue,
  getCurrencyByCode,
} from '@utils/formatCurrencyNumber';

export interface TransactionsWithId extends Transactions {
  id: string;
}

export const useCardTransactionsColumns = () => {
  const { t } = useTranslation();
  const { openDrawer } = useDrawer();
  const queryClient = useQueryClient();

  const getTransactionValues = (transaction: Transactions) => {
    if (transaction?.feeTransaction) {
      return {
        icon: ICONS.fee,
        description: transaction?.feeTransaction?.description,
      };
    }

    return {
      icon: ICONS['credit-card'],
      description: transaction?.cardTransaction?.merchantName,
    };
  };

  const columns: ColumnDef<TransactionsWithId>[] = [
    {
      accessorKey: 'effectiveAt',
      header: t('general.date'),
      cell: ({ getValue, row }) => (
        <TableDate
          date={getValue<string>()}
          className={clsx({
            'line-through': row.original.financialImpactType === 'NONE',
          })}
        />
      ),
      meta: {
        filter: {
          initialSelected: true,
          disabled: true,
        },
      },
    },
    {
      id: 'transactionType',
      header: '',
      cell: ({ row }) => {
        const { icon } = getTransactionValues(row.original);

        return <TableIcon icon={icon} />;
      },
      meta: {
        filter: {
          initialSelected: true,
        },
      },
    },
    {
      id: 'transactionName',
      header: t('general.description'),
      cell: ({ row }) => {
        const { description } = getTransactionValues(row.original);

        return (
          <TableItemTitleSub
            title={description}
            className={clsx({
              'line-through': row.original.financialImpactType === 'NONE',
            })}
          />
        );
      },
      meta: {
        width: 'auto',
        filter: {
          disabled: true,
          initialSelected: true,
        },
      },
    },
    {
      id: 'city',
      header: t('general.city'),
      cell: ({ row }) => {
        return (
          <TableItemTitleSub
            title={row.original?.cardTransaction?.merchantCity}
          />
        );
      },
    },
    {
      id: 'country',
      header: t('general.country'),
      cell: ({ row }) => {
        return (
          <TableItemTitleSub
            title={getCountryNameBySigla(
              row.original?.cardTransaction?.merchantCountry
            )}
          />
        );
      },
    },
    {
      accessorKey: 'originalAmount',
      header: t('general.original-amount'),
      cell: ({ row }) => {
        const cardTransaction = row.original?.cardTransaction;
        const isDebit = row.original.financialImpactType === 'DEBIT';

        return (
          <TableItemTitleSub
            className={clsx({
              'text-danger-500': isDebit,
              'line-through': row.original.financialImpactType === 'NONE',
            })}
            title={getCurrencyByCode(
              cardTransaction?.txnCurrency,
              cardTransaction?.txnAmount
            )}
          />
        );
      },
    },
    {
      accessorKey: 'billingAmountAuthorized',
      header: t('general.realized-amount'),
      cell: ({ getValue, row }) => {
        const isDebit = row.original.financialImpactType === 'DEBIT';
        return (
          <span
            className={clsx({
              'text-danger-500': isDebit,
              'line-through': row.original.financialImpactType === 'NONE',
            })}
            style={{ font: 'inherit' }}
          >
            {`${isDebit ? '-' : ''}${calcCurrencyValue(
              getValue<number>(),
              'BRL'
            )}`}
          </span>
        );
      },
      meta: {
        filter: {
          disabled: true,
          initialSelected: true,
        },
      },
    },
    {
      id: 'statement-actions',
      header: t('general.attachment'),
      cell: ({ row }) => {
        const isNullTransaction = row.original.financialImpactType === 'NONE';

        return (
          <Button
            variant="tertiary"
            size="small"
            className="w-8"
            data-testid="transaction-attachment"
            disabled={isNullTransaction}
            onClick={() => {
              openDrawer(
                <DrawerTransactionDetails
                  openSidebar
                  transactionId={row.original._id}
                  walletId={row.original.walletId}
                  updateCountFiles={() =>
                    queryClient.invalidateQueries({
                      queryKey: transactionKeys.all(),
                    })
                  }
                />
              );
            }}
          >
            <i
              className={`${ICONS.paperclip} text-p1 ${
                isNullTransaction ? 'text-neutral-50' : 'text-neutral-100'
              }`}
            />
            <p
              className={`text-p5 ${
                isNullTransaction ? 'text-neutral-50' : 'text-neutral-100'
              }`}
            >
              {row.original.receiptsCount}
            </p>
          </Button>
        );
      },
      meta: {
        filter: {
          initialSelected: true,
        },
      },
    },
  ];

  return useFilterColumns('card-transactions-columns', columns);
};
