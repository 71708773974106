import { useTranslation } from 'react-i18next';

import { ICONS } from '@constants/icons';
import { Button, Dialog, DropDownList, Spinner } from '@portao3-web/ui';
import { useCustomersReport } from './useCustomersReport';

export const ExportComponent = () => {
  const { t } = useTranslation();

  const { mutate: getCustomersReport, isPending } = useCustomersReport();

  return (
    <Dialog variant="popover">
      <Dialog.Trigger>
        <Button size="large" variant="secondary" disabled={isPending}>
          {isPending && <Spinner />}
          {t('general.export')}
          <i className={`${ICONS['chevron-down']} fa-1x`} />
        </Button>
      </Dialog.Trigger>

      <Dialog.Content>
        <DropDownList
          list={[
            {
              label: 'CSV',
              action: () => {
                getCustomersReport(null);
              },
            },
          ]}
        />
      </Dialog.Content>
    </Dialog>
  );
};
