import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';

import { ListResponse } from '@interfaces/Common.interfaces';
import { Customer } from '@interfaces/Customer.interfaces';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { findCustomerList } from '../findCustomerList';
import { customerKeys } from './customerKeys';

export const useInfiniteCustomerListQuery = (
  organizationId: string | null,
  query?: string,
  limit?: number
) => {
  return useInfiniteQuery<
    ListResponse<Customer>,
    ApiError,
    Customer[],
    unknown[],
    string
  >({
    queryKey: customerKeys.list(organizationId, query, limit),
    queryFn: ({ pageParam }) => findCustomerList(pageParam, query),
    getNextPageParam: (lastPage) => lastPage?.next,
    initialPageParam: '',
    select: (data) => data.pages.flatMap((page) => page.items),
    enabled: !!organizationId,
  });
};

export const useInfiniteCustomersListQuery = (name?: string | null) => {
  const organizationId = localStorage.getItem('organization');

  return useInfiniteQuery<
    ListResponse<Customer>,
    ApiError,
    InfiniteData<ListResponse<Customer>>,
    unknown[],
    string
  >({
    queryKey: customerKeys.list(organizationId, name),
    queryFn: ({ pageParam }) => findCustomerList(pageParam, name),
    getNextPageParam: (lastPage) => lastPage?.next || null,
    initialPageParam: '',
    enabled: !!organizationId,
  });
};
