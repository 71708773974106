import clsx from 'clsx';
import { useRef, useState } from 'react';

interface TableSearchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  setQuery?: (query: string) => void;
}

export const TableSearch = ({
  setQuery,
  className,
  onChange,
  ...props
}: TableSearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

  const handleSearchIconClick = () => {
    inputRef.current?.focus();
  };

  const handleSetQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);

    if (timerId) clearTimeout(timerId);

    const newTimerId = setTimeout(() => {
      setQuery?.(e.target.value);
    }, SECOND_IN_MILLISECONDS);

    setTimerId(newTimerId);
  };

  return (
    <div className="bg-neutral border-neutral-40 box-border flex h-8 w-72 items-center gap-2 rounded-md border border-solid pl-3 pr-4">
      <button
        onClick={handleSearchIconClick}
        className="flex h-4 w-4 items-center justify-center"
        disabled={props.disabled}
      >
        <i className="fa-regular fa-search text-neutral-70 text-p2" />
      </button>
      <input
        className={clsx(
          'placeholder:text-neutral-70 placeholder:text-p2 text-p2 disabled:bg-neutral w-full text-neutral-300 focus-visible:outline-none',
          className
        )}
        onChange={handleSetQuery}
        ref={inputRef}
        {...props}
      />
    </div>
  );
};

const SECOND_IN_MILLISECONDS = 1000;
