import { AxiosError } from 'axios';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import {
  ContextualError,
  ContextualErrorType,
  GeneralPresentationLogin,
  HeaderForm,
} from '@components';
import { regex } from '@constants/regex';
import { ErrorCodes, UserStatusCodes } from '@enums/ErrorCodes.enum';
import { ErrorRequest } from '@interfaces/ErrorRequest.interfaces';
import {
  Button,
  ErrorMessage,
  Input,
  Spinner,
  Typography,
} from '@portao3-web/ui';
import { AuthContext, ModalBlockAccess } from '@providers';

import { environment } from '@environments/environment';
import { useQueryParams } from '@hooks';
import { resetCustomerIoIdentity } from '@lib/customerIo';
import { useConfirmRecaptchaMutation } from '@services/recaptcha/hooks';
import { encryptToken } from '@utils/crypto';
import { User } from './interfaces/Login.interface';
import { preSignIn, signIn } from './services/loginServices';

export const Login = () => {
  const history = useNavigate();
  const { queryParams } = useQueryParams();
  const isBlocked = queryParams.get('blocked');

  const [showBlockedAccess, setShowBlockedAccess] = useState(!!isBlocked);
  const { t } = useTranslation();
  const { session, token, email } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<User>({
    mode: 'onBlur',
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ContextualErrorType>(null);
  const [recaptcha, setRecaptcha] = useState({
    show: false,
    isValid: true,
    code: '',
  });

  const { mutate: confirmRecaptchaMutate } = useConfirmRecaptchaMutation();

  function onChangeCaptcha(value: string | null) {
    if (!value) return;

    setRecaptcha({
      isValid: true,
      show: true,
      code: value,
    });
  }

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    resetCustomerIoIdentity();
  }, []);

  const submitForm = async (user: User, _: unknown, activeMFA?: boolean) => {
    setLoading(!loading);

    const submitLogin = async () => {
      try {
        if (!user.password) {
          email.setEmail(user.email);
          const preSignInResponse = await preSignIn(user);
          if (preSignInResponse.allowedMethod === 'PASSWORD') {
            setShowPassword(true);
            setLoading(false);
          }
        } else {
          const signInResponse = await signIn(user);
          const isActiveMFA =
            signInResponse?.challenge === 'SOFTWARE_TOKEN_MFA';

          localStorage.setItem(
            'loggedInCustomerIo',
            JSON.stringify({
              mfa: isActiveMFA,
              type: 'PASSWORD',
            })
          );

          const signInResponseEncrypt = JSON.stringify(
            encryptToken({
              accessToken: signInResponse.accessToken,
              refreshToken: signInResponse.refreshToken,
            })
          );

          if (signInResponse.challenge === 'NEW_PASSWORD_REQUIRED') {
            session.setSession(signInResponse.session);
            history('/new-password');
          } else if (signInResponse.challenge === 'SOFTWARE_TOKEN_MFA') {
            session.setSession(signInResponse.session);
            history('/mfa');
          } else if (activeMFA) {
            localStorage.setItem('token', signInResponseEncrypt);
            history('/active-mfa');
          } else {
            localStorage.setItem('token', signInResponseEncrypt);
            token.setToken(signInResponse.accessToken);
          }
        }
      } catch (error) {
        localStorage.clear();

        const errorService = error as AxiosError;
        const errorInfo = errorService.response?.data as Omit<
          ErrorRequest,
          'code'
        > & {
          code: UserStatusCodes & ErrorCodes;
        };

        if (errorInfo?.code === UserStatusCodes.BLOCKED_BY_PIN) {
          return setShowBlockedAccess(true);
        }

        setErrorMessage({
          message: t(
            `login.error-codes.${errorInfo?.code}`,
            t('error.default')
          ),
          traceId: errorInfo?.traceId,
        });

        if (
          errorInfo?.code === ErrorCodes.INVALID_CREDENTIALS &&
          environment.production
        ) {
          setRecaptcha({
            isValid: false,
            show: true,
            code: '',
          });

          (
            window as Window & typeof globalThis & { grecaptcha: ReCAPTCHA }
          )?.grecaptcha?.reset();
        }
      } finally {
        setLoading(false);
      }
    };

    if (!recaptcha.show) {
      return submitLogin();
    }

    confirmRecaptchaMutate(recaptcha.code, {
      onSuccess: async () => {
        submitLogin();
      },
      onError: () => {
        setRecaptcha({
          isValid: false,
          show: true,
          code: '',
        });

        setErrorMessage({
          message: t('error.recaptcha'),
          traceId: '',
        });

        setLoading(false);
      },
    });
  };

  return (
    <div className="authentication">
      <div className="authentication_content">
        <header className="authentication_img_header" />
        <GeneralPresentationLogin />
        <div className="authentication_content_right">
          <div className="authentication_content_right_form">
            {/* <Alert status="warning">
              <div className="flex-column w-full gap-8">
                <Typography
                  tag="p"
                  weight="p1"
                  color="var(--product-warning-w700)"
                >
                  Instabilidade Temporária
                </Typography>
                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n100)"
                >
                  Estamos resolvendo uma instabilidade em nossos servidores.
                  Agradecemos sua paciência!
                </Typography>
              </div>
            </Alert> */}
            <HeaderForm
              title="Boas-vindas à Portão 3!"
              subTitle="Comece agora a simplificar o controle das suas despesas."
            />
            <ContextualError error={errorMessage} />
            <form
              className="authentication_content_right_form_inputs"
              onSubmit={handleSubmit((data: User, event) =>
                submitForm(data, event, false)
              )}
            >
              <Input
                autoFocus
                type="email"
                placeholder="Digite seu e-mail"
                label="E-mail"
                id="email"
                name="email"
                data-testid="email"
                register={register}
                error={!!errors['email']}
                validationSchema={{
                  required: 'E-mail obrigatório',
                  pattern: {
                    value: regex.email,
                    message: 'E-mail inválido',
                  },
                }}
                required
              >
                {errors['email']?.message && (
                  <ErrorMessage message={errors['email'].message.toString()} />
                )}
              </Input>

              {showPassword && (
                <>
                  <Input
                    autoFocus={showPassword}
                    type="password"
                    placeholder="Digite sua senha"
                    label="Senha"
                    id="password"
                    name="password"
                    data-testid="password"
                    register={register}
                    error={!!errors['password']}
                    validationSchema={{
                      required: 'Campo de senha é obrigatório',
                    }}
                    required
                  >
                    {errors['password']?.message && (
                      <ErrorMessage
                        message={errors['password'].message.toString()}
                      />
                    )}
                  </Input>

                  <div className="authentication_content_right_form_request_password">
                    <Link to={'/forgot-password'} className="a">
                      Esqueceu sua senha?
                    </Link>
                  </div>
                </>
              )}

              <div className="authentication_submit">
                <Button
                  size="large"
                  disabled={!isValid || !recaptcha.isValid}
                  data-testid="submitForm"
                >
                  {loading ? <Spinner variant="secondary" /> : 'Avançar'}
                </Button>
              </div>

              <div
                className={clsx({
                  'my-4 flex w-full justify-center': true,
                  hidden: !showPassword || !recaptcha.show,
                })}
              >
                <ReCAPTCHA
                  sitekey={environment.recaptchaKey}
                  onChange={onChangeCaptcha}
                  onExpired={() =>
                    setRecaptcha({ isValid: false, show: true, code: '' })
                  }
                />
              </div>
            </form>

            <div className="authentication_not_account">
              <Typography tag="p">Ainda não possui uma conta?</Typography>
              <Link
                to={
                  'https://www.portao3.com.br/conta/cadastrop3?email=eduarda%40portao3.com.br&p=&is_self_onboarding='
                }
                target="_blank"
                className="a"
              >
                Fale com o time de vendas
              </Link>
            </div>
          </div>
          <Typography tag="p" className="authentication_copyright">
            © {new Date().getFullYear()} - Portão 3 - Todos os direitos
            reservados
          </Typography>
        </div>
      </div>

      {showBlockedAccess && <ModalBlockAccess />}
    </div>
  );
};
