interface TableItemTitleSubProps {
  title: string | undefined;
  subtitle?: string | undefined;
  className?: string;
}

export const TableItemTitleSub = ({
  title,
  subtitle,
  className,
}: TableItemTitleSubProps) => {
  return (
    <div className="flex flex-col">
      <p className={`text-p2  ${className}`}>{title || '-'}</p>

      {subtitle && (
        <p className={`text-p3 text-neutral-100 ${className}`}>{subtitle}</p>
      )}
    </div>
  );
};
